.block-mpobile{
  display: none !important;
 }

 .block-1 > img  {
  object-fit: cover !important;    
 }


@media screen and (max-width: 769px) {

  .contactus{
    flex-wrap: wrap;
  }

  .contacts{
    width: 100% !important;
    
  }


  .block-1  {
   flex-wrap: wrap;
  }

  .block-1 > img  {
    
    margin-top: 30px;
   }
}

@media screen and (max-width: 1164px) {
  .st-text {
    font-size: 99% !important;
  }

  .block-1 >img  {
    width: 55% !important;
   }
}

@media screen and (max-width: 842px) {
  .st-text {
    font-size: 99% !important;
  }
  .block-1   {
    flex-wrap: wrap;
   }

   .block-mpobile{
    display: block !important;
   }

   .block-2   {
    display: none !important;
   }

   .block-1 > img  {
    width: 90% !important;
    margin-top: 30px;
   }

    

   .aboutus-img{
    margin-top: 40px;
    width: 500px !important;
    height: 700px !important;
   }
  
}

@media screen and (max-width: 668px) {
  nav{
    display: none !important;
  }

  .burger-menu-button {
   
    visibility: visible !important; 
  }

  .secondary{
    
    display: none !important;
  }


  .st-text {
    font-size: 80% !important;
  }

}

@media screen and (max-width: 510px) {
  
  .header-title{
    font-size: 30px !important;
  }

  .header-text{
    font-size: 20px !important;
    width: 100% !important;
    word-break: break-all !important;

  }

  .aboutus-img{
    width: 100% !important;
   }

   input {
    width: 100% !important;
   }

   textarea {
    width: 100% !important;
    margin-bottom: 30px;
   }

   .second > p {
    font-size: 20px !important;
    width: 90% !important;
    margin-bottom: 40px !important;
   }

   .line{
    width: 90 !important;
   }

   .form{
    width: 100% !important;
   }

   .comment-tile{
    width: 90% !important;
    font-size: 30px !important;
   }

}

@media screen and (max-width: 398px) {
  .line{
    display: none;
  }

  .block-1 > div > p {
    width: 90%  !important;
  }

  .block-1 > div > h2 {
    width: 70%  !important;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1340px) {
  
  .main {
    padding-left: 300px !important;
  }

  .secondary {
    padding-right: 160px !important;
  }

  .form{
    padding-left: 200px !important;
  }

  .contacts{
    padding-right: 160px !important;
   
  }
}

@media screen and (min-width: 1500px) {
  
  

  .contacts{
   width: 700px !important;
  }
}


.video-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

/* You can style your menu items and other elements here */
/* .burger-menu {

} */

.burger-menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  visibility: hidden;
}

.menu-items {
  position: absolute;
  width: 80% !important;
  background-color: #fff; /* Change background color as needed */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 150px;
}

.menu-item {
  color: #333;
  text-decoration: none;
  margin-bottom: 5px;
}

.menu-item:hover {
  color: lightgray; /* Change hover color as needed */
}


.lang-btn{
  width: 50px;
  background:#000 ;
  color: #fff;
         border: none;
         padding: 10px;
         border-radius: 10px;
}